import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {isIOS,isIPad13	} from 'react-device-detect';
const ImageStrip = (props) => {
  return (
    <StripWrap style = {{backgroundAttachment: isIOS || isIPad13? 'scroll': 'fixed'}}>
      <Strip>

        <Body>
          <SectionHeader>Lorem Ipsum</SectionHeader>
          <Wrap>
            <One>
              <Image src={props.image}></Image>
            </One>
            <Two>
              <p>{props.text}</p>
              <Link to={props.buttonLink}>
                <Button
                  style={{
                    marginTop: "2em",
                    backgroundColor: props.buttonColor,
                    color: props.buttonTextColor,
                  }}
                >
                  {props.buttonText}
                </Button>
              </Link>
            </Two>
          </Wrap>
        </Body>
      </Strip>
    </StripWrap>
  );
};

export default ImageStrip;


const Button = styled.button`
  border: none;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  text-transform: uppercase;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.3s;
  font-weight: 600;
  color: #dfe6e9;
  margin: 0 1.5rem;

  :hover {
    transition: 0.3s;
    transform: translateY(-5px);
  }
`;

const SectionHeader = styled.h2`
  text-align: center;
  margin-bottom: 2em;
  font-size: 2em;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`;

const Image = styled.img`
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.3s;
  :hover {
    transition: 0.3s;
    transform: scale(0.9, 0.9);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

const Strip = styled.div`
  font-size: 18px;
  padding-top: 5em;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StripWrap = styled.div`
  color: white;
  background-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: linear-gradient(
      0deg,
      rgba(54, 54, 54, 0.7),
      rgba(54, 54, 54, 0.7)
    ),
    url("https://images.pexels.com/photos/3374210/pexels-photo-3374210.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260");
`;

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 5em;
  text-align: center;
  @media screen and (max-width: 800px) {
    display: inline;
    padding-bottom: 10em;
  }
`;

const One = styled.div`
  width: 50%;
  padding: 2em;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 2em;
    padding: 1em;
  }
`;

const Two = styled.div`
  width: 50%;
  padding: 2em;
  margin-left: 1em;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0em;
    padding: 1em;
    margin-bottom: 5em;
  }
`;
