import React from "react";
import styled from "styled-components";
import Top from './../top/Top'
import ImageStrip from './../strips/ImageStrip'
const Features = () => {
  return (
      <div>
         <Top title = {"Features"}></Top>
      <ImageStrip
      title = {"Test"}
      image = {"https://www.ropeiq.co.uk/landing-image.png"}
      text = {"Test"}
      color1 = {'#16a085'}
      color2 = {'#2c3e50'}
      textColor = {'black'}
      buttonColor = {'#8e44ad'}
      buttonTextColor = {'white'}
      buttonText = {"Find out more"}
      buttonLink = {"Find out more"}
      ></ImageStrip>

<ImageStrip
      title = {"Test"}
      image = {"https://www.ropeiq.co.uk/landing-image.png"}
      text = {"Test"}
      color1 = {'#2c3e50'}
      color2 = {'#16a085'}
      textColor = {"white"}
      buttonColor = {'#8e44ad'}
      buttonTextColor = {'white'}
      buttonText = {"Find out more"}
      buttonLink = {"Find out more"}
      ></ImageStrip>
            
    </div>

  );
};

export default Features;

