import React from "react";
import styled from "styled-components";
import BlogHome from "./BlogHome";

const Second = () => {
  return (
    <Body>
      <BlogHome></BlogHome>
    </Body>
  );
};

export default Second;


const Body = styled.div`
  margin: auto 0;
  padding: 5em;

  @media screen and (max-width: 800px) {
{
  padding: 2em;
}
 
`;
