import React from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

const BlogCard = (props) => {
  return (
    <div class="item-fade">
      <Link style={{ textDecoration: "none" }} to={"/Post/" + props.uId}>
        <div class = "post-container">
          <div class = "wrap">
            <div class = "one">
              <div class = "img"
                style={{ backgroundImage: `url(${props.imgUrl})` }}
              ></div>
            </div>
            <div class = "two-block">
              <span>
                <div class = "title-block">{props.title}</div> <p>{props.date}</p>
              </span>
              <div class = "description-block">{props.text}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogCard;

