import React from 'react';
import styled from "styled-components";

class Popup extends React.Component {
    render() {
      return(
        <PopupBody id="popup">
            <Container>
        <h2 className="green">{this.props.text}</h2>
        <button onClick={this.props.closePopup}>Close</button>
        </Container>
        </PopupBody>
      );
    }
  }

  export default Popup;
  let Container = styled.div`
    margin: auto;
    height: 400px;
    border-radius: 1em;
    width: 400px;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.3s;
    background-color: white;
    transition: fade 1s;
    transform: scale(0.9, 0.9);
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    @keyframes fadeInOpacity {
        0% {
            transform: scale(0%, 0%);
        
        }
        100% {
            transform: scale(100%, 100%);
        }
    }
 `;

  

  let PopupBody = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(88,88,88,0.5);
  align-items: center;
  margin-top: 40px;
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
  transition: fade 1s;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        
        }
        100% {
            opacity: 1;
        }
    }
    `;