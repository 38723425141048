import React from "react";
import ContactForm from "./ContactForm";
import ImageTop from "./../top/ImageTop";
import styled from "styled-components";
import { Helmet } from "react-helmet";
const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>brainy.software | Contact</title>
        <meta name="description" content="Get in touch with Brainy Software Today" />
      </Helmet>
      <ImageTop title = "Contact" showLogo = {true} imageUrl = 'https://images.pexels.com/photos/71116/hurricane-earth-satellite-tracking-71116.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'></ImageTop>
      <div class="body-container">
        <ContactForm></ContactForm>
      </div>
    </div>
  );
};

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  margin-bottom: 5em;
`;

export default Contact;
