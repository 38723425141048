import React,{useEffect} from "react";
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import Brand from "./Brand";
import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import Home from "./../home/Home";
import Resume from "./../resume/Resume";
import Contact from "./../contact/Contact";
import CaseStudy from "./../case-study/CaseStudy";

import Blog from "./../blog/Blog";
import Post from "./../post/Post";
import Features from "./../features/Features";
import Analytics from 'react-router-ga';
import ScrollToTop from "./../../classes/ScrollToTop";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./../../App.css";
import "./Navbar.css"
import ProposalPage from "../proposal/ProposalPage";
import Proposal from "../proposal/Proposal";


const Navbar = (props) => {


  const barAnimation = useSpring({
    from: { transform: "translate3d(0, -10rem, 0)" },
    transform: "translate3d(0, 0, 0)",
  });

  const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  return (
    <Router   >
      <Analytics id="UA-130632893-4" >
      <ScrollToTop></ScrollToTop>
      <div class = "nav-bar" style={barAnimation}>
        <div class = "flex-container">
          <Brand />
          <div class = "nav-links-style" style={linkAnimation}>
            <a>
              <Link to="/">Home</Link>
            </a>
            <a>
              <Link to="/About">About</Link>
            </a>           
            <a>
              <Link to="/Blog">Blog</Link>
            </a>
            <a
              style={{
                boxShadow:
                  "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
                backgroundColor: "#e6195e",
                padding: "0.4em",
                borderRadius: "5px",
              }}
            >
              <Link to="/Contact">Contact</Link>
            </a>
          </div>
          <div class = "burger-wrapper">
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </div>
        </div>
      </div>
      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
      <Route      
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={450} classNames="fade">
              <Switch>
                alert('g');
                <Route exact path="/" component={Home} />
                <Route path="/About" component={Resume}></Route>
                <Route path="/Contact" component={Contact}></Route>
                <Route path="/Blog" component={Blog}></Route>
                <Route path="/Features" component={Features}></Route>
                <Route path="/Post/:id" component={Post}></Route>
                <Route path="/Case-Study/:id" component={CaseStudy}></Route>
                <Route path="/Proposal/:id" component={Proposal}></Route>

              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
      </Analytics>
    </Router>
  );
};

export default Navbar;

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #2c3e50;
  z-index: 1000000;
  font-size: 1.4rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: 5rem;
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;

  & a {
    color: #dfe6e9;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #8e44ad;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;
