import React, { useEffect, useState } from "react";
import ImageTop from "../top/ImageTop";
import { RichText } from "prismic-reactjs";
import { client } from "../../classes/prismicHelpers";
import "./Proposal.css";
import { Helmet } from "react-helmet";
const Proposal = (props, { match }) => {
  const [prismicDoc, setPrismicDoc] = useState(null);
  const [notFound, toggleNotFound] = useState(false);

  const uid = props.match != null ? props.match.params.id : null;

  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const doc = await client.getByUID("proposal", uid);

        if (doc) {
          setPrismicDoc(doc);
        } else {
          console.warn(
            "Blog Proposal document was not found. Make sure it exists in your Prismic repository"
          );
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };

    fetchPrismicData();
  }, [uid]);
  if (prismicDoc) {
    const title =
      prismicDoc.data.blogtitle.length !== 0
        ? RichText.asText(prismicDoc.data.blogtitle)
        : "Untitled";
    return (
      <div class="fader">
        <Helmet>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <ImageTop title={title} imageUrl = {prismicDoc.data.image.url} showLogo = {true}></ImageTop>
        <div class="body">
          <div class="Proposal-body">{RichText.render(prismicDoc.data.text)}</div>
        </div>
      </div>
    );
  }
  return null;
};

export default Proposal;
