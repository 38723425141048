import React, { Component } from "react";
import styled from "styled-components";
import {isIOS,isIPad13	} from 'react-device-detect';

import "./top.css";
class ImageTop extends Component {
  render() {
    const logo = this.props.showLogo ? (
      <Image src="/assets/brainy1.png"></Image>
    ) : null;

    return (
      <div>
        <ImageContainer
        
          style={{ backgroundImage: "linear-gradient(0deg, rgba(54, 54, 54, 0.7), rgba(54, 54, 54, 0.7)),url(" + this.props.imageUrl + ")", backgroundAttachment: isIOS || isIPad13 ? 'scroll': 'fixed'  }}
        >
          <div>
            {logo}
            <Title>{this.props.title}</Title>
          </div>
        </ImageContainer>
      </div>
    );
  }
}
export default ImageTop;

const Title = styled.h2`
   color: white;
  font-size: 5em;
  width: 50vw;
  margin-top: 2em;
  margin-left: 5em;
  margin-right: 5em;

  @media screen and (max-width: 800px) {
    font-size: 3em;
    width: 80%;
    margin-right: auto;
    margin-left:auto;

  }


`;

const ImageContainer = styled.div`

background-color: rgba(255, 0, 0, 0.25);
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  text-align: center;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media screen and (max-width: 800px) {
  }

`;

const Image = styled.img`
  display: inline-block;
  width: 60vw;
  max-width: 1000px;
  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;
