import styled from "styled-components";
import React from "react";

const CaseStudyTestimonial = (props) => {
  return (
    <Container>
      <Quote>"{props.text}"</Quote>

      <By>{props.title}</By>
    </Container>
  );
};

export default CaseStudyTestimonial;

const Quote = styled.div`
  color: black;
  font-size: 2em;
  font-style: italic;
  margin-bottom: 1em;
`;

const By = styled.div`
  color: #e6195e;
  font-size: 3em;
  font-style: bold;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 2em;
    border-radius: 2em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 3em;
`;
