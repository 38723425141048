import React, { Component,useEffect } from 'react'
import Navbar from "./components/navbar/Navbar";
import GlobalStyle from './styles/Global';
import Footer from './components/footer/Footer'
import ReactGA from 'react-ga';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const initGA = (history) => {
 

 
};

history.listen((location) => {
  console.error("tracking page view: " + location.pathname);
  
});

class App extends Component {
  state = {
    navbarOpen: false
  }

  

  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen });
  }



  render() {
    initializeReactGA();
    return (
      <>
        <Navbar 
          navbarState={this.state.navbarOpen} 
          handleNavbar={this.handleNavbar}
        />
        <Footer></Footer>
        <GlobalStyle />
      </>
    )
  }
}


function initializeReactGA() {
  ReactGA.initialize('UA-130632893-4');
}

export default App