import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';

import { useSpring, animated } from 'react-spring';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
const CollapseMenu = (props) => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

  if (props.navbarState === true) {
    return (
      <CollapseWrapper style={{
        transform: open.interpolate({
          range: [0, 0.2, 0.3, 1],
          output: [0, -20, 0, -200],
        }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
      }}
      >
        <NavLinks>
          <li><a><Link to="/">Home</Link></a></li>
          <li><a><Link to="/About">About</Link></a></li>
          <li><a><Link to="/Blog">Blog</Link></a></li>
          <li><a><Link to="/Contact">Contact</Link></a></li>
        </NavLinks>
      </CollapseWrapper>
    );
  }
  return null;
};


export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
  background: #363636;
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;

  z-index: 100000;

  @media screen and (min-width: 800px){
    display: none;
  }
`;

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;

  & li {
    transition: all 300ms linear 0s;
  }

  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: #dfe6e9;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #8e44ad;
      border-bottom: 1px solid #8e44ad;
    }
  }
`;