import React from 'react'
import styled from "styled-components";

const Footer = () => {
return (
    <Container></Container>
);
}

export default Footer;

let Container = styled.div`
height: 400px;
background-color: #2980b9;
background-image: linear-gradient( #363636,#363636);

`;