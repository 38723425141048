import React, { useEffect, useState } from "react";
import { client } from "./../../classes/prismicHelpers";
import Second from "./Second";
import ImageStrip from "../strips/ImageStrip";
import Fourth from "./Fourth";
import { Helmet } from "react-helmet";
import ContactForm from "./../contact/ContactForm";
import styled from "styled-components";
import ImageTop from "../top/ImageTop";
import { isIOS, isIPad13 } from "react-device-detect";

const Home = () => {
  const [prismicDoc, setPrismicDoc] = useState(null);
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const doc = await client.getSingle("home");

        if (doc) {
          setPrismicDoc(doc);
        } else {
          console.warn(
            "Blog post document was not found. Make sure it exists in your Prismic repository"
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchPrismicData();
  });
  if (prismicDoc) {
    var data = prismicDoc.data;
    console.info(data);

    return (
      <div>
        <Helmet>
          <title>brainy.software | Home</title>
          <meta name="description" content="brainy.boftware" />
        </Helmet>

        <div class="fader">
          <ImageTop
    title="Working Today To Develop The Software Of Tomorrow."
    showLogo={true}
    imageUrl="https://images.pexels.com/photos/1434608/pexels-photo-1434608.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
    />
          <Second
    image1={data.cardimage1.url}
    image2={data.cardimage2.url}
    image3={data.cardimage3.url}
    title1={data.cardtitle1[0].text}
    title2={data.cardtitle2[0].text}
    title3={data.cardtitle3[0].text}
    />
          <ImageStrip
    title={data.section1head[0].text}
    image={data.section1image.url}
    text={data.section1text[0].text}
    color1={"#2c3e50"}
    color2={"#16a085"}
    textColor={"white"}
    buttonColor={"#e6195e"}
    buttonTextColor={"white"}
    buttonText={data.section1buttontext}
    buttonLink={data.section1buttonurl}
    />
          <Fourth title={data.blogstitle[0].text}/>
          <Strip
            style={{
              backgroundAttachment: isIOS || isIPad13 ? "scroll" : "fixed",
            }}
          >
            <div>
              <ContactForm/>
            </div>
          </Strip>
          <Strip style={{ backgroundImage: "none" }}>
            <div>
              <BottomTitle>Thanks for visiting!</BottomTitle>
              <BottomText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </BottomText>
            </div>
          </Strip>
        </div>
      </div>
    );
  }
  return null;
};

const Strip = styled.div`
  font-size: 18px;
  padding-top: 5em;
  padding-bottom: 5em;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      0deg,
      rgba(54, 54, 54, 0.7),
      rgba(54, 54, 54, 0.7)
    ),
    url("https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260");
  background-attachment: fixed;
  background-size: cover;
  text-align: center;
`;

let BottomTitle = styled.h1`
color: #e6195e;
font-size: 2em;`;

let BottomText = styled.p`
max-width: 800px;
margin-top: 2em;
`;

let Image = styled.img`
  width: 200px;
`;

export default Home;
