import React, { useEffect, useState } from "react";
import ImageTop from "./../top/ImageTop";
import { RichText } from "prismic-reactjs";
import { client } from "./../../classes/prismicHelpers";
import "./Post.css";
import { Helmet } from "react-helmet";
const Post = (props, { match }) => {
  const [prismicDoc, setPrismicDoc] = useState(null);
  const [notFound, toggleNotFound] = useState(false);

  const uid = props.match != null ? props.match.params.id : null;

  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const doc = await client.getByUID("blog-post", uid);

        if (doc) {
          setPrismicDoc(doc);
        } else {
          console.warn(
            "Blog post document was not found. Make sure it exists in your Prismic repository"
          );
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };

    fetchPrismicData();
  }, [uid]);
  if (prismicDoc) {
    const title =
      prismicDoc.data.blogtitle.length !== 0
        ? RichText.asText(prismicDoc.data.blogtitle)
        : "Untitled";
    return (
      <div class="fader">
        <Helmet>
          <title>brainy.software | {title}</title>
          <meta name="description" content="Get in touch with Rope IQ Today" />
        </Helmet>

        <ImageTop title={title} imageUrl = {prismicDoc.data.image.url} showLogo = {true}></ImageTop>
        <div class="body">

                      <div class="post-body">{RichText.render(prismicDoc.data.text)}</div>
        </div>
      </div>
    );
  }
  return null;
};

export default Post;
