import React from "react";
import Second from "./Second";
import styled from "styled-components";
import Top from './../top/Top'
import {Helmet} from 'react-helmet'
import ImageTop from "../top/ImageTop";

const Resume = () => {
  return (
    <div>
      <Helmet>
      <title>brainy.software | About Us</title>
      <meta
        name="description"
        content="Rope IQ is the complete wire rope management software for all organisations, big or small."
      />
    </Helmet>
    <ImageTop title = "About" showLogo = {true} imageUrl = 'https://images.pexels.com/photos/5439/earth-space.jpg?auto=compress&cs=tinysrgb&h=750&w=1260'></ImageTop>
      <Body>
        <Second></Second>
      </Body>
    </div>
  );
};

export default Resume;

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  margin-top: 5em;
  margin-bottom: 5em;
  border-radius: 1em;
  padding: 3em;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  @media screen and (max-width: 800px) {

`;
