import React, { useEffect, useState } from "react";
import { RichText } from "prismic-reactjs";
import { client } from "./../../classes/prismicHelpers";
import Top from "./../top/Top";
import { Helmet } from "react-helmet";
import CaseStudyIntroduction from "./CaseStudyIntroduction";
import CaseStudyResult from "./CaseStudyResult";
import styled from 'styled-components';
import CaseStudyTestimonial from "./CaseStudyTestimonial";
import ContactForm from './../contact/ContactForm';

const CaseStudy = (props) => {
  const [prismicDoc, setPrismicDoc] = useState(null);
  const [toggleNotFound] = useState(false);
  const uid = props.match != null ? props.match.params.id : null;

  useEffect( () => {
    const getData = async () => {
      await getPrismicData(uid, setPrismicDoc, toggleNotFound);
    };
    const fetchPrismicData = getData;

    fetchPrismicData();
  }, [uid]);

  if (prismicDoc) {
    var list = getResultsList(prismicDoc);
      var testimonials = getTestimonialsList(prismicDoc);
    return view(prismicDoc, list,testimonials);
  }
  return null;
};


export default CaseStudy;

function getResultsList(prismicDoc) {
  const results = prismicDoc.data.results;
  var list = getResults(results);
  return list;
}

function getTestimonialsList(prismicDoc){
  const testimonials = prismicDoc.data.testimonails;
  var list = getTestimonials(testimonials);
  return list;
}

async function getPrismicData(uid, setPrismicDoc, toggleNotFound) {
  try {
    const doc = await client.getByUID("case-study", uid);
    if (doc) {
      setPrismicDoc(doc);
    } else {
      console.warn(
        "Blog post document was not found. Make sure it exists in your Prismic repository"
      );
      toggleNotFound(true);
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

function view(prismicDoc, list,testimonials) {
  return (
    <div class = "fader">
      <Helmet>
        <title>
          brainy.software | {RichText.asText(prismicDoc.data.bannertitle)}
        </title>
        <meta
          name="description"
          content={RichText.asText(prismicDoc.data.section1text)}
        />
      </Helmet>
      <Top title={RichText.asText(prismicDoc.data.bannertitle)}></Top>
      <Title>The Challenge</Title>

      <CaseStudyIntroduction
        title={RichText.asText(prismicDoc.data.section1title)}
        imageUrl={prismicDoc.data.section1image.url}
        text={RichText.asText(prismicDoc.data.section1text)}
      ></CaseStudyIntroduction>
      <Title>Results</Title>

      <ResultsContainer>
      <ul>{list}</ul>
      </ResultsContainer>
      <Title>Feedback</Title>
      <ResultsContainer>
      <ul>{testimonials}</ul>
      </ResultsContainer>
     </div>
  );
}

function getResults(results) {
  var b = true;
  var list = results.map(function (post) {
    b = !b;
    return (
      <CaseStudyResult
        leftToRight={b}
        title={RichText.asText(post.title)}
        text={RichText.asText(post.text)}
        img={post.image.url}
      ></CaseStudyResult>
    );
  });
  return list;
}

function getTestimonials(testimonials) {
  var b = true;
  var list = testimonials.map(function (post) {
    b = !b;
    return (
      <CaseStudyTestimonial
       
        title={RichText.asText(post.title1)}
        text={RichText.asText(post.quote)}
      ></CaseStudyTestimonial>
    );
  });
  return list;
}


const ResultsContainer = styled.div`
padding-bottom: 5em;
`

const Title = styled.div`
text-align: center;
font-size: 4em;
font-weight: bold;
margin-bottom: 3em;
margin-top: 3em;

`

const Strip = styled.div`
font-size: 18px;
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
background: linear-gradient(-135deg, #e6195e,#e6195e,#363636);
`;
