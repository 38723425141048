import React from "react";
import BlogHome from "./../blog/BlogHome";

import styled from "styled-components";

const Fourth = (props) => {
  return (
    <Body>
      <div>
        <Title>{props.title}</Title>
      <BlogHome pageSize = {3}></BlogHome>
      </div>
    </Body>
  );
};

export default Fourth;

const Title = styled.h2`
text-align: center;
margin-bottom: 2em;
font-size: 2em;
`;

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  font-size: 18px;
  min-height: 100vh;
  margin-top: 3em;
  margin-bottom: 3em;
  display: flex;
  justify-content: center;
  align-items: center;`;
