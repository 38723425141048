import React from "react";
import styled from "styled-components";
import "./Second.css";
import Popup from "./../popup/Popup";
import ReactDom from "react-dom";

class Second extends React.Component {
  constructor() {
    super();
    this.state = {
      showPopup: false,
    };
  }
  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  render() {
    return (
      <BodyContainer>
      <Body>
        <div>
          <Title>Lorem Ipsum</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Paragraph>
          <div class="cards-list item-fade">
            <div class="card 1" onClick={this.togglePopup.bind(this)}>
              <div
                class="card_image"
                style={{
                  backgroundImage:
                    "linear-gradient(0deg, rgba(54, 54, 54, 0.5), rgba(54, 54, 54, 0.5)),url(" +
                    this.props.image1 +
                    ")",
                }}
              ></div>
              <div class="card_title title-white">
                <p>{this.props.title1}</p>
              </div>
            </div>

            <div class="card 2">
              <div
                class="card_image"
                style={{
                  backgroundImage:
                    "linear-gradient(0deg, rgba(54, 54, 54, 0.5), rgba(54, 54, 54, 0.5)),url(" +
                    this.props.image2 +
                    ")",
                }}
              ></div>
              <div class="card_title title-white">
                <p>{this.props.title2}</p>
              </div>
            </div>

            <div class="card 3">
              <div
                class="card_image"
                style={{
                  backgroundImage:
                    "linear-gradient(0deg, rgba(54, 54, 54, 0.5), rgba(54, 54, 54, 0.5)),url(" +
                    this.props.image3 +
                    ")",
                }}
              ></div>
              <div class="card_title title-white">
                <p>{this.props.title3}</p>
              </div>
            </div>
          </div>
        </div>
      </Body>
      </BodyContainer>
    );
  }
}

export default Second;

const Paragraph = styled.p`
  font-size: 1.5em;
  text-align: center;
  margin-top: 3em;
  margin-left: 2em;
  margin-right: 2em;
  color: black;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 4em;
  color: #e61985;

`;

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  min-height: 100vh;
  margin-top: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BodyContainer = styled.div`
`;
