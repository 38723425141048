import React from 'react';
import styled from 'styled-components';

const CaseStudyResult = (props)=>{
    return (
        <Container>
            <Wrap>
            <One>
            <Image src = {props.img}></Image>
            </One>
            <Two>
            <Title>{props.title}</Title>
            <Paragraph>{props.text}</Paragraph>
            </Two>
            </Wrap>
        </Container>);

      
    
}

export default CaseStudyResult;

const Wrap = styled.div`
display: flex;
color: black;
align-items: center;
margin-bottom: 5em;
  text-align: center;
  @media screen and (max-width: 800px) {
    margin: 1em;
    display: inline;
    flex-direction: row-reverse;
    
  }
`;

const One = styled.div`
  width: 50%;
  padding: 3em;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 2em;
    padding: 2em;
    float: right;
    padding-bottom: 3em;
    padding-top: 3em;
  }
`;

const Two = styled.div`
  width: 50%;
  padding: 3em;
  margin-left: 1em;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0em;
    padding: 2em;
    padding-bottom: 3em;
    padding-top: 3em;


  }
`;


const Title = styled.div`
font-size: 3em;
margin-bottom: 2em;
color: #e6195e
`;

const Paragraph = styled.div`
font-size: 1.5em;
color: #363636;
`;

const Image = styled.img`
max-width: 100%;
border-radius: 5px;
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
transition: 0.1s;

:hover{
    @media screen and (min-width: 800px) {

  }
}
`;

const Container = styled.div`
max-width: 1000px;
margin-left: auto; 
margin-right: auto;
`;