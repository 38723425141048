import React from 'react'
import styled from "styled-components";

const Brand = () => {
  return (
    <Container>
    <Image src = {"/assets/brainy1.png"}/>
    </Container>
  )
}

export default Brand

const Container = styled.div`
align-items: center;
height: 100%;
display: flex;
`;
const Image = styled.img`
max-width: none;
height: 30px;
`;
