import React from "react";
import "./contact-form.css";

const ContactForm = () => {
  return (
    <React.Fragment>
  <div class="background">
  <div class="container">
    <div class="screen">
      <div class="screen-header">
        <div class="screen-header-left">
          <div class="screen-header-button close"></div>
          <div class="screen-header-button maximize"></div>
          <div class="screen-header-button minimize"></div>
        </div>
        <div class="screen-header-right">
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
        </div>
      </div>
      <div class="screen-body">
        <div class="screen-body-item left">
          <div class="app-title">
            <span>Get In</span>
            <span>Touch</span>

          </div>
          <div class="app-contact">CONTACT INFO : jgillespie@brainy.software</div>
        </div>
        <div class="screen-body-item">
          <div class="app-form">
            <div class="app-form-group">
              <input type = "text" class="app-form-control" placeholder="name"></input>
            </div>
            <div class="app-form-group">
              <input type = "email" class="app-form-control" placeholder="email"></input>
            </div>
            <div class="app-form-group">
              <input class="app-form-control" placeholder="phone number"></input>
            </div>
            <div class="app-form-group message">
              <input class="app-form-control" placeholder="message"></input>
            </div>
            <div class="app-form-group buttons">
              <button class="app-form-button">SEND</button>
            </div>
          </div>
        </div>
      </div>
    </div>   
  </div>
</div>


    </React.Fragment>
  );
};

export default ContactForm;
const buttonOptions = {
  text: "Submit",
  width: "100%",
  type: "default",
  id: "myButton",
  useSubmitBehavior: true,
};

var captchaToken = null;

var formData = {
  message: "",
  email: "",
  firstname: "",
  "g-recaptcha-response": "",
};
