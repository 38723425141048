import React from 'react';
import styled from 'styled-components';

const CaseStudyIntroduction = (props) => {
    return(
        <div>
      
        <Paragraph>
            {props.text}
        </Paragraph>
        <ImageContainer>
        <Image src = {props.imageUrl}></Image>
        </ImageContainer>
        </div>
    );

}

export default CaseStudyIntroduction;



const Paragraph = styled.div`
text-align: center;
max-width: 800px;
margin: 3em;
margin-left: auto;
margin-right: auto;
font-size: 2em;
`

const Image = styled.img`
max-width: 100%;
margin: 2em;
margin-left:auto;
margin-right: auto;
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
border-radius: 5px;
transition: 3s;
:hover{
    transition: 3s;
    transform: scale(1.05);
}
`

const ImageContainer = styled.div`
text-align: center;
max-width: 1000px;
margin-left:auto;
margin-right: auto;
margin-bottom: 5em;
margin-top: 5em;

`