import React from "react";
import ImageTop from "./../top/ImageTop";
import Second from "./Second";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import './blog.css'
const Blog = () => {
  return (
    <div>
      <Helmet>
        <title>brainy.software | Blog</title>
        <meta
          name="description"
          content="Take a look at some of our very popular blog posts."
        />
      </Helmet>
      <ImageTop title = "Blog" showLogo = {true} imageUrl = 'https://images.pexels.com/photos/41953/earth-blue-planet-globe-planet-41953.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'></ImageTop>
      <div class = "blog-body">
        <Second></Second>
      </div>
    </div>
  );
};

export default Blog;

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  margin-bottom: 5em;
`;
