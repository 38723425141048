import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { RichText,Link } from 'prismic-reactjs';
import { client } from "./../../classes/prismicHelpers";

const Second = (props) => {
  const [prismicDoc, setPrismicDoc] = useState(null);
  const [ toggleNotFound] = useState(false);


  useEffect(() => {
      const fetchPrismicData = async () => {
        try {
          const doc = await client.getSingle('about');
    
          if (doc) {
            setPrismicDoc(doc);
          } else {
            console.warn('Blog post document was not found. Make sure it exists in your Prismic repository');
          }
        } catch (error) {
          console.error(error);
        }
      }
  
      fetchPrismicData();
    });
    if (prismicDoc) {
      console.info(prismicDoc);
     
  return (
    <Body>
    {RichText.render(prismicDoc.data.text)}     
    </Body>
  );}
  return null;
};

export default Second;

const Body = styled.div`
img{
  max-width: 100%;
  border-radius: 1em;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

}

background-color: white;
margin: auto 0;
padding: 5em;

pre {
color: lightgray;
background-color: black;
padding: 2em;
border-radius: 1em;
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
font-size: 14px;
overflow-x:scroll;    }



  h1{
    font-size: 4em;
    color: black;
  
    text-align: left;
    margin-bottom: 1em;
  }

  h2{
    font-size: 3em;
    color: darkgray;
  
    text-align: left;
    margin-bottom: 1em;
  }
  p{
    font-size: 18px;
  color: black;
  margin-bottom: 1em;

  text-align: left;
  }

  ul{
    margin-top: 4em;
  margin-left: 3em;
  }

  li{
    font-size: 18px;
    color: black;
    margin-bottom: 1em;
  
    text-align: left;
  }

  @media screen and (max-width: 800px) {
    {
      padding:0em;
    }
`;
