import React, { useEffect, useState } from "react";
import { RichText } from "prismic-reactjs";
import BlogCard from './BlogCard'
import { Predicates } from "prismic-javascript";
import { client } from "./../../classes/prismicHelpers";


const BlogHome = (props) => {
  const [prismicData, setPrismicData] = useState({ blogPosts: null });
  // Get the homepage and blog post documents from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const blogPosts = await client.query(
          Predicates.at("document.type", "blog-post")
          ,{ pageSize : props.pageSize == null? 20: props.pageSize, orderings:  '[document.last_publication_date desc]' }
        );
        setPrismicData({ blogPosts: blogPosts.results });
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrismicData();
  }, []);

  if (prismicData.blogPosts) {
    const blogPosts  = prismicData.blogPosts;
    var list = blogPosts.map(function(post){
        return <BlogCard date = {post.blogdate} uId = {post.uid} text = {post.data.description[0].text}imgUrl = {post.data.image.url}title = {post.data.blogtitle[0].text}></BlogCard>;
      });
    return (
          <ul>{ list }</ul>
    )
  }
  return null;
};

export default BlogHome;
