import styled from "styled-components";
import React from "react";
import Proposal from "./Proposal"
import Top from "../top/Top"
const ProposalPage = (props, { match }) => {
    return(
  <div>
      <Proposal></Proposal>
  </div>);
};

export default ProposalPage;

const Body = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  margin-top: 5em;
  margin-bottom: 5em;
  border-radius: 1em;
  padding: 3em;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
`;
